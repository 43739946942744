"use client";

import { useLocaleContext } from "../../../helpers/LocaleContext";
import { getRbImageUrl } from "../../../helpers/images/getRbImageUrl";
import { dataLayer } from "../../../helpers/dataLayer";
import { CosmosText, CosmosTitle } from "@cosmos/web/react";
import { useViewportDimensions } from "../../../helpers/useViewportDimensions";
import { useImageLoading } from "../../../helpers/ImageLoadingContext";
import classnames from "classnames/bind";
import styles from "./athlete.module.css";

const cx = classnames.bind(styles);

const TABLET_BREAKPOINT = 650;

export type AthleteType = {
  id: string;
  mediaUrl: string;
  alt: string | null;
  productRelatedQuote: string | null;
  url: string | null;
  title: string | null;
  disciplines: string[];
};

export const Athlete = ({
  card,
  allCards,
}: {
  card: AthleteType;
  allCards: AthleteType[];
}) => {
  const locale = useLocaleContext();

  const { viewportWidth } = useViewportDimensions();

  const imageLoading = useImageLoading();

  return (
    <div className={cx("container")}>
      <div className={cx("background-image")}>
        <picture>
          <source
            media="(min-width: 650px)"
            srcSet={[650, 768, 1024, 1222]
              .flatMap((width) => [width, width * 2]) // <- Provide high-DPI (aka. "retina") variants
              .map((width) => {
                const src = getRbImageUrl(
                  card.mediaUrl,
                  {
                    width,
                    ar: 1.93,
                    g: "auto",
                    q: 80,
                  },
                  locale,
                );

                return `${src} ${width}w`;
              })
              .join(", ")}
          />
          <source
            srcSet={[343, 390, 420, 650]
              .flatMap((width) => [width, width * 2]) // <- Provide high-DPI (aka. "retina") variants
              .map((width) => {
                const src = getRbImageUrl(
                  card.mediaUrl,
                  {
                    width,
                    ar: "3:4",
                    g: "auto",
                  },
                  locale,
                );

                return `${src} ${width}w`;
              })
              .join(", ")}
          />
          <img
            loading={imageLoading}
            className={cx("background-image-image")}
            src={card.mediaUrl}
            sizes="(min-width: 650px) min(1222px, 100vw), min(343px, 100vw)"
            alt={card.alt ?? ""}
          />
        </picture>
      </div>
      <div className={cx("content")}>
        <div className={cx("content-inner")}>
          <div className={cx("title")}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 72 54"
              className={cx("title-icon")}
            >
              <path
                fill="currentColor"
                d="M0 54V40.696c8.268-1.566 9.208-4.109 9.208-12.913H0V2a2 2 0 0 1 2-2h24a2 2 0 0 1 2 2v22.652C28 42.848 18.604 52.63 0 54zm44 0V40.696c8.268-1.566 9.208-4.109 9.208-12.913H44V2a2 2 0 0 1 2-2h24a2 2 0 0 1 2 2v22.652C72 42.848 62.604 52.63 44 54z"
              ></path>
            </svg>

            <CosmosTitle
              size={
                viewportWidth < TABLET_BREAKPOINT + 200 ? "medium" : "large"
              }
              tag="h2"
            >
              {card.productRelatedQuote}
            </CosmosTitle>
          </div>
          <a
            className={cx("profile")}
            href={card.url ?? undefined}
            onClick={() =>
              analyticsClick({
                item: card,
                items: allCards,
              })
            }
          >
            {card.mediaUrl && (
              <div className={cx("profile-image")}>
                <img
                  loading={imageLoading}
                  className={cx("profile-image-image")}
                  srcSet={[62, 124]
                    .map((size) => {
                      const src = getRbImageUrl(
                        card.mediaUrl,
                        {
                          width: size,
                          height: size,
                        },
                        locale,
                      );
                      return `${src} ${size}w`;
                    })
                    .join(", ")}
                  sizes="62px"
                  alt={card.alt ?? ""}
                />
              </div>
            )}
            <div className={cx("profile-text")}>
              <CosmosText className={cx("profile-text-name")} weight="medium">
                {card.title}
              </CosmosText>
              <CosmosText className={cx("profile-text-discipline")}>
                {card.disciplines.join(", ")}
              </CosmosText>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

function analyticsClick({
  item,
  items,
}: {
  item: AthleteType;
  items: AthleteType[];
}) {
  dataLayer.push({
    event: "moduleClick",
    availableElements: items.length,
    promotionmodule: "card-stack_standard",
    promotionclicktype: "Photo",
    ecommerce: {
      promoClick: {
        promotions: [
          {
            id: item.id,
            name: item.title,
            creative: "Athlete Rail",
            position: items.findIndex(
              (singleItem) => singleItem.id === item.id,
            ),
          },
        ],
      },
    },
  });
}
